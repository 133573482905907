import { React, useState, useEffect } from 'react'
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button, Form } from 'react-bootstrap';
import { ShimmerTitle } from "react-shimmer-effects";





export default function DetaileSection(prop) {
    const { data, setdata, brand } = prop;
    const mainUrl = process.env.REACT_APP_EXPRESS_APP_URL;
    let invnetoryStock = data.quantity
    const [valueCart, setValueCart] = useState(1);
    const [cartData, setCartData] = useState([]);

    const product_id = data.part_number;

    const product_quantity = valueCart;
    const category_type = 'wheel';
    const price = data.msrp_usd
    const S3URL = "https://extremewheelsdevelopment.s3-accelerate.amazonaws.com/";
    let image = data.s3_images;
    const vehical_details = JSON.parse(localStorage.getItem('vehical_details'));
    const fitmentType = localStorage.getItem('vehical_fitment');
    const [affirmLoad, setAffirmLoad] = useState(false);
    const [getLoader, setLoader] = useState(false);



    if (!image) {

        if (data.images) {
            image = data.images

        } else {
            image = S3URL + brand.logo_s3_url
        }
    } else {
        image = S3URL + data.s3_images
    }
    // console.log('image+++++++++++++', image)

    const [showModal, setShowModal] = useState(false);
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [comments, setComments] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    // const year = vehical_details ? vehical_details.year : "";
    // const make_name = vehical_details ? vehical_details.make_name : "";
    // const model_name = vehical_details ? vehical_details.model_name : "";
    // const variant_name = vehical_details ? vehical_details.variant_name : "";
    // const fitment = fitmentType ? fitmentType : "";




    useEffect(() => {
        const affirmConfig = {
            public_api_key: "Z6PMLBXW7DT8S738",
            script: "https://cdn1.affirm.com/js/v2/affirm.js"
        };

        const initializeAffirm = () => {
            const { affirm } = window;

            const priceUpdateEventHandler = (newPrice) => {
                // console.log("priceUpdateEventHandler");
                // changePriceHandler(newPrice);
            }

            const updateAffirmPromos = (newPrice) => {
                document.getElementById('affirmProductModal').setAttribute('data-amount', newPrice);
                document.getElementById('affirmLearnMore').setAttribute('data-amount', newPrice);
                affirm.ui.refresh();
            }

            affirm.ui.ready(() => {
                const newPrice = 699;

                // Listen for the Affirm Learn More modal close event
                affirm.events.on('learnMore:close', () => {
                    //    console.log('Affirm Learn More modal closed');
                });

                // Add this script to an existing callback that fires when the price updates
                priceUpdateEventHandler(newPrice);

                // Update Affirm promos with the initial price
                updateAffirmPromos(newPrice);
            });
        };

        // Load Affirm script
        const loadAffirmScript = () => {
            const script = document.createElement('script');
            script.async = true;
            script.src = affirmConfig.script;
            script.onload = initializeAffirm;
            document.body.appendChild(script);
            setAffirmLoad(true)

        };

        // Run the initialization after 5 seconds
        setTimeout(loadAffirmScript, 8000);

        return () => {
            // Cleanup: Remove Affirm script when component is unmounted
            // document.body.removeChild(script);
        };
    }, []);


    let featuresTires = data.features;
    const dataDesc = data.part_description;
    const lines = dataDesc.split('\n');

    function calculateLevenshteinDistance(a, b) {
        if (a.length === 0) return b.length;
        if (b.length === 0) return a.length;

        const matrix = [];
        for (let i = 0; i <= b.length; i++) {
            matrix[i] = [i];
        }

        for (let j = 0; j <= a.length; j++) {
            matrix[0][j] = j;
        }

        for (let i = 1; i <= b.length; i++) {
            for (let j = 1; j <= a.length; j++) {
                const cost = a[j - 1] === b[i - 1] ? 0 : 1;
                matrix[i][j] = Math.min(
                    matrix[i - 1][j] + 1,
                    matrix[i][j - 1] + 1,
                    matrix[i - 1][j - 1] + cost
                );
            }
        }

        return matrix[b.length][a.length];
    }

    const uniqueLines = [lines[0]];

    for (let i = 1; i < lines.length; i++) {
        let isUnique = true;

        for (let j = 0; j < uniqueLines.length; j++) {
            if (calculateLevenshteinDistance(lines[i], uniqueLines[j]) <= 3) {
                // Adjust the threshold as needed for similarity
                isUnique = false;
                break;
            }
        }

        if (isUnique) {
            uniqueLines.push(lines[i]);
        }
    }
    const result = uniqueLines.join('\n');


    let product_name = `${data.brand}  ${data.line} ${data.size} ${data.loadindex}  ${data.speedrating}`;

    // if (year) {
    //     if (fitment) {
    //         product_name = `${data.brand}  ${data.line} ${data.size} ${data.loadindex}  ${data.speedrating} \n(${year}, ${make_name}, ${model_name}, ${variant_name},${fitment})`;

    //     } else {
    //         product_name = `${data.brand}  ${data.line} ${data.size} ${data.loadindex}  ${data.speedrating} \n(${year}, ${make_name}, ${model_name}, ${variant_name})`;

    //     }

    // }

    const apiAddtoCart = async (cartItems) => {
        // console.log('image+++++++++++++', image)
        setLoader(true);
        try {
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${mainUrl}/api/add-to-cart`,
                credentials: 'include', // Send cookies with the request
                withCredentials: true,
                params: {
                    product_image: image,
                    product_id: data.item_id,
                    product_name: product_name,
                    product_price: data.price,
                    product_quantity: valueCart,
                    category_type: "tires_cat",
                    total_quantity: invnetoryStock,
                    part_number: product_id
                },
                data: {
                    cart_key: localStorage.getItem("cart-key") ? localStorage.getItem("cart-key") : '',
                    user_token: localStorage.getItem("user-token") ? localStorage.getItem("user-token") : ''
                }
            };
            axios
                .request(config)
                .then((response) => {
                    localStorage.setItem("cart-key", response.data.addCartResponse)
                    localStorage.setItem('totalCartItems', JSON.stringify(cartItems));
                    setCartData(cartItems);
                    setdata(cartItems);
                    toast.success("Item Added to cart!", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setLoader(false);
                })
                .catch((error) => {
                    console.log(error);
                    setLoader(false);
                });
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoader(false);


        }
    };

    const addItemstoCaart = () => {
        const cartItems = JSON.parse(localStorage.getItem('totalCartItems')) || {};
        const itemId = data.part_number
        const existingValue = cartItems[itemId] || 0;
        const updatedValue = existingValue + valueCart;
        // Check if the updated value exceeds the stockInventory
        if (updatedValue > invnetoryStock) {
            toast.info("Cannot add more items than available in stock!", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            return;
        }
        cartItems[itemId] = updatedValue;
        apiAddtoCart(cartItems);
    }

    const increaseValue = () => {

        if (valueCart < invnetoryStock) {
            setValueCart(valueCart + 1);

        }

    };

    const decreaseValue = () => {
        if (valueCart > 1) {
            setValueCart(valueCart - 1);

        }
    }

    function removeDecimalIfZeros(number) {
        const formattedNumber = Number((parseFloat(number)).toFixed(2)); // Set the desired number of decimal places
        const decimalPart = formattedNumber % 1;
        return decimalPart === 0 ? (parseFloat(formattedNumber)).toFixed(0) : formattedNumber;
    }
    function addSign(number) {
        if (number > 0) {
            return '+' + number;
        } else if (number < 0) {
            return '-' + Math.abs(number);
        } else {
            return number;
        }
    }


    const handleEnquiryModel = () => {
        setShowModal(true)
    }
    const handleClose = () => {
        setShowModal(false)
    }


    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value);
    };

    const handleLastNameChange = (e) => {
        setLastName(e.target.value);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePhoneChange = (e) => {
        // Remove non-numeric characters from input
        const cleanedInput = e.target.value.replace(/\D/g, '');
        setPhone(cleanedInput);
    };

    const handleCommentsChange = (e) => {
        setComments(e.target.value);
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission here, e.g. sending the inquiry data to a backend API
        // You can access email, phone, and comments state variables here
        // Reset form fields if needed
        try {

            try {
                let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: `${mainUrl}/api/submit-inquiry`,
                    credentials: 'include', // Send cookies with the request
                    withCredentials: true,
                    params: {
                        firstName: firstName,
                        lastName: lastName,
                        email: email,
                        phone: phone,
                        comments: comments,

                    }
                };
                axios
                    .request(config)
                    .then((response) => {
                        toast.success("Submitted successfully!", {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });
                        // Reset form fields and close the modal
                        setEmail('');
                        setPhone('');
                        setComments('');
                        handleClose();

                    })
                    .catch((error) => {
                        console.log(error);

                    });
            } catch (error) {
                console.error('Error fetching data:', error);

            }

        } catch (error) {
            console.error('Error submitting inquiry:', error);
        }


    };

    function createUnorderedList(inputString) {
        const lines = inputString.split('\n');
        const uniqueLines = new Set(lines);
        const uniqueLinesArray = Array.from(uniqueLines);
        const listItems = uniqueLinesArray.map(line => `<li>${line}</li>`).join('\n');
        const unorderedList = `<ul>\n${listItems}\n</ul>`;
        return unorderedList;
    }


    return (
        <>
            <div className="main-product-data">
                {/* <ToastContainer
                    position="top-center"
                    autoClose={1000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"

                /> */}
                <div className="product-name-details">
                    <span className='tag_name'>{data.brand}</span>
                    <h2>{data.brand + " " + data.line + " " + data.size + " " + data.loadindex + " " + data.speedrating}</h2>
                    <span className='prod_cat'>{data.sub_type}</span>
                    <div className="stock">
                        <h3 className="m-0">Stock</h3>
                        <h4 className="m-0">
                            {
                                data.quantity > 0 ?
                                    <span className=''><i className="fa fa-circle me-1"></i> In stock ( {data.quantity} )</span>
                                    :
                                    <span className='text-danger'><i className="fa fa-circle me-1"></i>Out of Stock</span>
                            }

                        </h4>
                        <h5 className="m-0">SKU: <span>{data.part_number}</span></h5>
                    </div>
                    <div id="prices" style={{ height: '25px' }}>
                        {
                            !affirmLoad ?
                                <ShimmerTitle line={1} gap={10} variant="primary" />
                                :
                                <p id="learn-more" className="affirm-as-low-as" data-amount={`${data.price * 100}`} data-affirm-color="blue" data-learnmore-show="true" data-page-type="product"> </p>


                        }
                    </div>

                    <div className="product-deastil-preice">
                        <h2>${parseFloat(data.price).toFixed(2)}</h2>
                        {
                            data.price >= 100 ? (
                                <h2 style={{ fontSize: "14px", fontWeight: "500" }}>
                                    &nbsp;<i className="fa fa-truck" aria-hidden="true"></i> &nbsp; Free Shipping<b style={{ fontSize: '10px' }}>(Refer Terms & Conditions)</b>
                                </h2>
                            ) : (
                                ""
                            )
                        }

                    </div>

                    <div className="quantity">
                        {
                            invnetoryStock > 0 ?
                                <>
                                    <div className="incres">
                                        <div className="value-button" id="decrease" onClick={decreaseValue}>
                                            <i className="fa fa-minus"></i>
                                        </div>
                                        <input type="text" id="number" value={valueCart} readOnly />
                                        <div className="value-button" id="increase" onClick={increaseValue}>
                                            <i className="fa fa-plus"></i>
                                        </div>
                                    </div>

                                    <div className="cart">
                                        {!getLoader ?
                                            <button className="" onClick={addItemstoCaart}>
                                                <svg className="me-1" width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M0.982759 1.96567H4.07847L5.65092 10.9827C5.7738 11.6214 6.48624 12.2849 7.10048 12.2849H17.6654C18.3288 12.2849 18.8201 11.6952 18.8938 11.1056L21.3263 1.42519C21.3508 1.35149 21.3508 1.2778 21.3508 1.17942C21.3508 0.516003 20.8347 0.00013155 20.1223 0.00013155H7.91125C7.37073 0.00013155 6.92856 0.442295 6.92856 0.982824C6.92856 1.52335 7.37073 1.96552 7.91125 1.96552H19.1887L18.8202 3.43977H8.59927C8.05874 3.43977 7.61658 3.88194 7.61658 4.42247C7.61658 4.963 8.05874 5.40516 8.59927 5.40516H18.3042L17.9356 6.87955H9.1397C8.59917 6.87955 8.15701 7.32171 8.15701 7.86224C8.15701 8.40277 8.59917 8.84493 9.1397 8.84493H17.4442L17.0757 10.3192H7.54275L5.87211 0.810794C5.79842 0.343929 5.38077 0 4.9139 0H0.982693C0.442163 0 0 0.442163 0 0.982692C0 1.52322 0.442163 1.96556 0.982693 1.96556L0.982759 1.96567Z" fill="white" />
                                                    <path d="M16.0444 18.4272C17.4449 18.4272 18.575 17.2725 18.575 15.8474C18.575 14.5207 17.6168 13.4396 16.3639 13.2923H16.3393H16.2656H16.1428H8.59993H8.55074H8.45253C7.05198 13.2923 5.92188 14.447 5.92188 15.8721C5.9217 17.297 7.05198 18.4272 8.42783 18.4272C9.80368 18.4272 10.9585 17.2725 10.9585 15.8474C10.9585 15.6263 10.934 15.4297 10.8848 15.2332H13.612C13.5628 15.4297 13.5383 15.6263 13.5383 15.8474C13.5383 17.2724 14.6686 18.4272 16.0445 18.4272H16.0444ZM16.0444 15.2332C16.3391 15.2332 16.6094 15.5034 16.6094 15.8474C16.6094 16.1914 16.3636 16.4616 16.0444 16.4616C15.7249 16.4616 15.4793 16.1914 15.4793 15.8474C15.4791 15.5034 15.7494 15.2332 16.0444 15.2332ZM8.42781 16.4616C8.13303 16.4616 7.86276 16.1914 7.86276 15.8474C7.86276 15.5034 8.10852 15.2332 8.42781 15.2332C8.74727 15.2332 8.99287 15.5034 8.99287 15.8474C8.99287 16.1914 8.74727 16.4616 8.42781 16.4616Z" fill="white" />
                                                </svg>
                                                Add to Cart
                                            </button>
                                            :
                                            <button className="" disabled>
                                                Loading...
                                            </button>
                                        }
                                    </div>
                                    <div className="Finance">
                                        <a href='https://consumer.snapfinance.com/#/?mid=490293126' target='_blank'>
                                            <button>Click to Finance</button>
                                        </a>
                                    </div>
                                </>
                                : <>
                                    <div className="cart">

                                        Out of stock !

                                    </div>
                                    <div className="Finance">
                                        <button className="Finance" title='Item out of stock' onClick={handleEnquiryModel}>
                                            Click for Enquiry</button>
                                    </div>
                                </>
                        }

                    </div>
                </div>

                {/* <div className='subCategoryNameDetailsPage'>
                    <span className='sub_cat_name'>
                        Wheels
                    </span>
                </div> */}




                {/* <div className="mainAttributes mt-2">
                    <div className='row'>
                        <div className='col-md-6 d-flex'>
                            <div className='iconImage'>
                                <img src='/icons/wheel-diameter.svg' alt='img' height="100%" width="100%" />
                            </div>
                            <div className='d-grid'>
                                <span><strong>Rim Size</strong></span>
                                <span className='px-4 mt-1'>{removeDecimalIfZeros(data.rim)}"</span>
                            </div>
                        </div>
                        <div className='col-md-6 d-flex'>
                            <div className='iconImage' >
                                <img src='/icons/wheel-width.svg' alt='img' height="100%" width="100%" />
                            </div>
                            <div className='d-grid'>
                                <span><strong>Tyre Aspect Ratio</strong></span>
                                <span className='px-4 mt-1'>{removeDecimalIfZeros(data.ratio)}</span>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6 d-flex'>
                            <div className='iconImage'>
                                <img src='/icons/wheel-offset.svg' alt='img' height="100%" width="100%" />
                            </div>
                            <div className='d-grid'>
                                <span><strong>Load Index</strong></span>
                                <span className='px-4 mt-1'>{data.loadindex ? data.loadindex : "N/A"}</span>
                            </div>
                        </div>
                        <div className='col-md-6 d-flex'>
                            <div className='iconImage'>
                                <img src='/icons/lug-pattern.svg' alt='img' height="100%" width="100%" />
                            </div>
                            <div className='d-grid'>
                                <span><strong>Speed Rating</strong></span>
                                <span className='px-4 mt-1'>{data.speedrating ? data.speedrating : "N/A"}</span>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="producDescription">
                    {result ?
                        <>
                            <strong>About this product </strong><br></br>
                            {result}
                        </> : ""
                    }
                </div>
                <div className="producDescription">
                    {data.features ?
                        <>
                            <strong>Features </strong><br></br>
                            <div dangerouslySetInnerHTML={{ __html: createUnorderedList(featuresTires) }} />
                        </>
                        : ""
                    }
                </div>
                <strong>Specifications </strong><br></br>
                <div className='singleWheelSpecification mt-2'>
                    <table className='table' style={{ fontSize: '14px' }}>
                        <tbody>

                            {/* row 1 */}
                            <tr>
                                <td style={{ borderLeft: '1px solid #dddddd', width: '25%', paddingLeft: '10px' }}><strong>Item SKU </strong></td>
                                <td style={{ borderRight: '1px solid #dddddd', width: '25%' }}>{data.part_number ? data.part_number : "N/A"}</td>

                                <td style={{ paddingLeft: '10px', width: '30%' }}><strong>Size</strong></td>
                                <td style={{ borderRight: '1px solid #dddddd', width: '20%' }}>{data.size}</td>


                            </tr>

                            {/* row 2 */}
                            <tr>
                                <td style={{ borderLeft: '1px solid #dddddd', width: '25%', paddingLeft: '10px' }}><strong>Brand</strong></td>
                                <td style={{ borderRight: '1px solid #dddddd', width: '25%' }}>{data.brand ? data.brand : "N/A"}</td>

                                <td style={{ borderLeft: '1px solid #dddddd', width: '25%', paddingLeft: '10px' }}><strong>Quicksize</strong></td>
                                <td style={{ borderRight: '1px solid #dddddd', width: '20%' }}>{data.quicksize}</td>

                            </tr>


                            {/* row 3 */}
                            <tr>
                                <td style={{ borderLeft: '1px solid #dddddd', width: '25%', paddingLeft: '10px' }}><strong>Model</strong></td>
                                <td style={{ borderRight: '1px solid #dddddd', width: '25%' }}>{data.line ? data.line : "N/A"}</td>

                                <td style={{ borderLeft: '1px solid #dddddd', width: '25%', paddingLeft: '10px' }}><strong>Load Range</strong></td>
                                <td style={{ borderRight: '1px solid #dddddd', width: '25%' }}>{data.load_range}</td>


                            </tr>

                            {/* row 4 */}
                            <tr>

                                <td style={{ borderLeft: '1px solid #dddddd', width: '25%', paddingLeft: '10px' }}><strong>Section Width</strong></td>
                                <td style={{ borderRight: '1px solid #dddddd', width: '25%' }}>{removeDecimalIfZeros(data.widthint)}</td>


                                <td style={{ borderLeft: '1px solid #dddddd', width: '25%', paddingLeft: '10px' }}><strong>Max Load Capacity</strong></td>
                                <td style={{ borderRight: '1px solid #dddddd', width: '25%' }}>{data.max_load_capacity && data.max_load_capacity != 0 ? data.max_load_capacity : "N/A"}</td>


                            </tr>


                            {/* row 5 */}
                            <tr>
                                <td style={{ borderLeft: '1px solid #dddddd', width: '25%', paddingLeft: '10px' }}><strong>Aspect Ratio</strong></td>
                                <td style={{ borderRight: '1px solid #dddddd', width: '25%' }}>{data.ratio}</td>

                                <td style={{ borderLeft: '1px solid #dddddd', width: '25%', paddingLeft: '10px' }}><strong>Ply</strong></td>
                                <td style={{ borderRight: '1px solid #dddddd', width: '20%' }}>{data.ply && data.ply != 0 ? data.ply : "N/A"}</td>



                            </tr>


                            {/* row 6 */}
                            <tr>

                                <td style={{ borderLeft: '1px solid #dddddd', width: '25%', paddingLeft: '10px' }}> <strong>Diameter</strong></td>
                                <td style={{ borderRight: '1px solid #dddddd', width: '25%' }}>{removeDecimalIfZeros(data.rimint)}</td>

                                <td style={{ borderLeft: '1px solid #dddddd', width: '25%', paddingLeft: '10px' }}><strong>Inflation Pressure </strong></td>
                                <td style={{ borderRight: '1px solid #dddddd', width: '20%' }}>{data.inflation_pressure && data.inflation_pressure != 0 ? data.inflation_pressure + "psi" : "N/A"}</td>

                            </tr>

                            {/* row 7 */}
                            <tr>
                                <td style={{ borderLeft: '1px solid #dddddd', width: '25%', paddingLeft: '10px' }}><strong>Load Index</strong></td>
                                <td style={{ borderRight: '1px solid #dddddd', width: '25%' }}>{data.loadindex && data.loadindex != 0 ? data.loadindex : "N/A"}</td>

                                <td style={{ paddingLeft: '10px', width: '30%' }} ><strong>Sidewall</strong></td>
                                <td style={{ borderRight: '1px solid #dddddd', width: '20%' }}>{data.sidewall ? data.sidewall : "N/A"}</td>



                            </tr>

                            {/* row 8 */}
                            <tr>

                                <td style={{ borderLeft: '1px solid #dddddd', paddingLeft: '10px', width: '30%' }}><strong>Speedrating</strong></td>
                                <td style={{ borderRight: '1px solid #dddddd', width: '20%' }}>{data.speedrating ? data.speedrating : "N/A"}</td>

                                <td style={{ paddingLeft: '10px', width: '30%' }}><strong>Tread Depth</strong></td>
                                <td style={{ borderRight: '1px solid #dddddd', width: '20%' }}>{data.tread_depth && data.tread_depth != 0 ? removeDecimalIfZeros(data.tread_depth) + "/32nds" : "N/A"}</td>

                            </tr>


                            {/* row 9 */}
                            <tr>

                                <td style={{ borderLeft: '1px solid #dddddd', width: '25%', paddingLeft: '10px' }}><strong>Run Flat</strong></td>
                                <td style={{ borderRight: '1px solid #dddddd', width: '20%' }}>{data.run_flat == 0 ? "No" : "Yes"}</td>

                                <td style={{ paddingLeft: '10px', width: '30%' }}><strong>Warranty</strong></td>
                                <td style={{ borderRight: '1px solid #dddddd', width: '20%' }}>{data.warranty == 0 || data.warranty.length != 5 ? "No" : "Yes(" + data.warranty + ")"}</td>




                            </tr>


                            {/* row 10 */}
                            <tr>
                                <td style={{ borderLeft: '1px solid #dddddd', paddingLeft: '10px', width: '30%' }}><strong>UTQG</strong></td>
                                <td style={{ borderRight: '1px solid #dddddd', width: '20%' }}>{data.utqg ? data.utqg : "N/A"}</td>

                                <td style={{ paddingLeft: '10px', width: '30%' }}><strong>Vehicle Type</strong></td>
                                <td style={{ borderRight: '1px solid #dddddd', width: '20%' }}>{data.sub_type}</td>


                            </tr>

                            {/* row 11 */}
                            <tr>

                                <td style={{ borderLeft: '1px solid #dddddd', paddingLeft: '10px', width: '30%' }}><strong>Treadwear</strong></td>
                                <td style={{ borderRight: '1px solid #dddddd', width: '20%' }}>{data.treadwear && data.treadwear != 0 ? data.treadwear : "N/A"}</td>

                                <td style={{ paddingLeft: '10px', width: '30%' }}><strong>Tread Design</strong></td>
                                <td style={{ borderRight: '1px solid #dddddd', width: '20%' }}>{data.category == 'uncategorized' ? 'General' : data.category}</td>



                            </tr>

                            {/* row 12 */}
                            <tr>

                                <td style={{ borderLeft: '1px solid #dddddd', paddingLeft: '10px', width: '30%' }}><strong>Temperature</strong></td>
                                <td style={{ borderRight: '1px solid #dddddd', width: '20%' }}>{data.temperature ? data.temperature : "N/A"}</td>

                                <td style={{ paddingLeft: '10px', width: '30%' }}><strong>Studdable </strong></td>
                                <td style={{ borderRight: '1px solid #dddddd', width: '20%' }}>{data.studdable == 0 ? "No" : "Yes"}</td>



                            </tr>

                            {/* row 13 */}
                            <tr>

                                <td style={{ borderLeft: '1px solid #dddddd', paddingLeft: '10px', width: '30%' }}><strong>Traction </strong></td>
                                <td style={{ borderRight: '1px solid #dddddd', width: '20%' }}>{data.traction && data.traction != 0 ? data.traction : "N/A"}</td>

                                <td style={{ paddingLeft: '10px', width: '30%' }}><strong>Studded</strong></td>
                                <td style={{ borderRight: '1px solid #dddddd', width: '20%' }}>{data.studded == 0 ? "No" : "Yes"}</td>

                            </tr>

                            {/* <tr>
                                <td style={{ borderLeft: '1px solid #dddddd', width: '25%', paddingLeft: '10px' }}><strong>Sidewall</strong></td>
                                <td style={{ borderRight: '1px solid #dddddd', width: '20%' }}>{data.sidewall}</td>
                                <td style={{ paddingLeft: '10px', width: '30%' }}><strong></strong></td>
                                <td style={{ borderRight: '1px solid #dddddd', width: '20%' }}></td>
                            </tr>  */}

                        </tbody>
                    </table>
                </div>
            </div>
            <Modal show={showModal} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Your Enquiry About : {data.product_name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit} className='enquiry-form-class'>

                        <div className='row'>
                            <div className='col-md-6'>
                                <Form.Group controlId="email">
                                    <Form.Label>First Name*</Form.Label>
                                    <Form.Control
                                        type="firstName"
                                        placeholder="Enter first name"
                                        value={firstName}
                                        onChange={handleFirstNameChange}
                                        required
                                    />
                                </Form.Group>
                            </div>

                            <div className='col-md-6'>
                                <Form.Group controlId="lastName">
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control
                                        type="tel"
                                        placeholder="Enter last name"
                                        value={lastName}
                                        onChange={handleLastNameChange}
                                    />
                                </Form.Group>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-6'>
                                <Form.Group controlId="email">
                                    <Form.Label>Email*</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter email"
                                        value={email}
                                        onChange={handleEmailChange}
                                        required
                                    />
                                </Form.Group>
                            </div>

                            <div className='col-md-6'>
                                <Form.Group controlId="phone">
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control
                                        type="tel"
                                        placeholder="Enter phone number"
                                        value={phone}
                                        onChange={handlePhoneChange}
                                    />
                                </Form.Group>
                            </div>
                        </div>


                        <div className='row'>
                            <div className='col-md-12'>
                                <Form.Group controlId="comments">
                                    <Form.Label>Year,Make,Model & Comments*</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        placeholder="Enter your comments"
                                        value={comments}
                                        onChange={handleCommentsChange}
                                        required
                                    />
                                </Form.Group>
                            </div>

                        </div>
                        <div className='row'>
                            <div className='col-md-12 text-end'>
                                <Form.Group controlId="comments">
                                    <Button variant="danger" type="submit">
                                        Submit
                                    </Button>
                                </Form.Group>
                            </div>
                        </div>

                    </Form>
                </Modal.Body>
            </Modal>

        </>
    )
} 
